.c-button {
  --background: var(--color-dark);
  --border-radius: 4px;

  display: block;
  padding: 13px 16px;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: var(--color-dark);
  color: var(--color-white);
  font-size: rem(13);
  text-align: center;
  cursor: pointer;
  transition:
    background 0.3s ease-in-out,
    color 0.3s ease-in-out;

  @include breakpoint(medium) {
    padding: 16px 32px;
    font-size: rem(14);
  }

  &:hover:not(.shiny):not(.primary):not(.border):not(.attention):not(.icon) {
    background: transparent;
    border-color: var(--color-dark);
    color: var(--color-dark);
  }

  &.primary {
    --background: var(--color-primary);

    &:hover:not(.shiny) {
      background: var(--color-white);
      color: var(--color-dark);
    }
  }
  &.rounded {
    --border-radius: 99px;
  }
  &.uppercase {
    text-transform: uppercase;
  }

  &_wrap {
    display: inline-block;
  }

  &.transparent {
    background: rgba(250, 250, 250, 0.15);
    font-weight: var(--font-medium);
  }

  &.transparent-black {
    background: rgba(0, 0, 0, 0.2);
  }

  &.border {
    background: transparent;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);

    &:hover {
      color: var(--color-white);
      background: var(--color-dark);
    }
  }

  &.primary {
    background: var(--color-primary);
  }

  &.white {
    background: var(--color-white);
    color: var(--color-dark);
  }

  &.attention {
    padding: 26px 57px 26px 24px;
    overflow: visible;
    border-radius: 99px;
    font-weight: var(--font-medium);
    cursor: pointer;
    min-width: 287px;
    border: none;
    background: linear-gradient(180deg, #484848, #1a1a1a);
    box-shadow:
      0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
      color: var(--color-white);
      .mask {
        animation: mask1 0.4s steps(22) forwards;
      }
    }

    &.primary {
      background: transparent !important;

      .attention_box3 {
        background: var(--color-primary);
      }

      .mask {
        background: #1b1b1b;
      }
    }

    .mask {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 99px;
      background: var(--color-primary);
      -webkit-mask: url('/assets/nature-sprite.webp');
      mask: url('/assets/nature-sprite.webp');
      -webkit-mask-size: 2300% 100%;
      mask-size: 2300% 100%;
      border: none;
      color: var(--color-white);
      cursor: pointer;
      animation: mask2 0.4s steps(22) forwards;
    }

    .attention_box1 {
      --half-width: 2.83;
      --start-position: 8px;
      --mid-top-position: 141.5px;
      --mid-bottom-position: 93.5px;
      --end-position: 228px;
      position: absolute;
      left: 0;
      top: 0;
      box-shadow:
        rgb(125, 110, 77) 0px -5px 12px -5px,
        rgb(125, 110, 77) 0px -7px 22px -10px;
      width: 48px;
      height: 64px;
      border-radius: 999px;
      animation: attention 3s linear infinite;
      user-select: none;
      pointer-events: none;
    }
    .attention_box2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 999px;
      overflow: hidden;
      pointer-events: none;
      span {
        position: absolute;
        left: 0;
        user-select: none;
        pointer-events: none;
        animation: attention2 3s linear infinite;
        width: 48px;
        height: 72px;
        background: conic-gradient(
          rgb(125, 110, 77) 0%,
          transparent 15.5%,
          transparent 87%,
          rgb(125, 110, 77) 98%
        );
        --button-width: 235px;
        --width-multiplier: 5.895833333333333;
      }
    }
    .attention_box3 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 999px;
      transition: opacity 0.3s ease-in;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      pointer-events: none;
      background: #1b1b1b;
      color: #fff;
      transition: background 0.3s ease;
    }
    .c-button_text {
      position: relative;
      color: var(--color-white);
    }
  }

  &.fill {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: rem(20) rem(16);
    border-radius: 99px;
    background: var(--background);
    font-size: rem(13);
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: var(--font-medium);
    cursor: pointer;
    box-shadow:
      0 6px 10px #0000000a,
      0 2px 4px #0000000a;
    transition:
      background cubic-bezier(0.35, 0, 0, 1) 0.2s,
      color 0.1s ease;

    @include breakpoint(medium) {
      padding: rem(24);
      font-size: rem(14);
    }

    &:hover {
      color: var(--background);
      background: var(--color-primary);
      transition: background cubic-bezier(0.35, 0, 0, 1) 0.5s 0.1s;

      .c-button_dot {
        transform: translate3d(8em, 0, 0) scale(22);
      }

      .c-button_text {
        transform: translate3d(-1.5em, 0, 0);
      }

      .c-button_icon {
        transform: translateZ(0);
      }
    }

    .c-button_dot {
      display: inline-block;
      position: relative;
      width: 15px;
      height: 15px;
      background: var(--color-white);
      border-radius: 100px;
      z-index: 1;
      transition:
        background cubic-bezier(0.35, 0, 0, 1) 0.5s,
        transform cubic-bezier(0.35, 0, 0, 1) 0.4s;
    }

    .c-button_text {
      position: relative;
      z-index: 1;
      transition:
        color cubic-bezier(0.35, 0, 0, 1) 0.5s,
        transform cubic-bezier(0.35, 0, 0, 1) 0.4s;
    }

    .c-button_icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: rem(16);
      height: 1.2em;
      width: 1.2em;
      border-radius: 100%;
      transform: translate3d(3em, 0, 0);
      transition: transform cubic-bezier(0.4, 0, 0, 1) 0.4s;
      z-index: 1;

      svg {
        width: 100%;
        height: 100%;
        path {
          stroke: var(--background);
        }
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    margin-left: auto;
  }

  &.shiny {
    position: relative;
    background: var(--color-primary);
    transition:
      background 0.3s ease-in-out,
      color 0.3s ease-in-out;
    overflow: hidden;
    border: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      width: 30%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.5;
      filter: blur(25px);
      transform: translateX(-150%) skewX(-15deg);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      width: 15px;
      height: 100%;
      left: 30px;
      top: 0;
      opacity: 0;
      filter: blur(25px);
      transform: translateX(-80px) skewX(-15deg);
    }

    &:hover {
      color: var(--color-white);
    }

    &:hover:before {
      transform: translateX(400%) skewX(-15deg);
      opacity: 1;
      transition: 0.9s;
    }

    &:hover:after {
      transform: translateX(450%) skewX(-15deg);
      opacity: 1;
      transition: 0.9s;
    }
  }

  &.submiting {
    position: relative;
    overflow: hidden;
    min-width: 194px;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #f1f1f1;
      left: 4px;
      top: 11px;
      border-radius: 50%;
      opacity: 0;
      animation: move 1s infinite ease-in-out;
    }
  }

  &.submitted {
    background: var(--color-green);
  }

  &.icon {
    @include breakpoint(medium) {
      padding: 14px 20px;
    }

    &:hover {
      background: var(--color-primary);
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes attention {
  0% {
    transform: translate(var(--start-position)) scaleX(1);
  }
  25% {
    transform: translate(var(--mid-top-position)) scaleX(var(--half-width));
  }
  35% {
    transform: translate(var(--end-position)) scaleX(1);
  }
  50% {
    transform: translate(var(--end-position)) rotate(180deg) scaleX(1);
  }
  75% {
    transform: translate(var(--mid-bottom-position)) rotate(180deg)
      scaleX(var(--half-width));
  }
  85% {
    transform: translate(var(--start-position)) rotate(180deg) scaleX(1);
  }
  100% {
    transform: translate(var(--start-position)) rotate(1turn) scaleX(1);
  }
}
@keyframes attention2 {
  0% {
    transform: translate(0) scaleX(1);
  }
  33.33% {
    transform: translate(var(--button-width)) scaleX(var(--width-multiplier));
  }
  50% {
    transform: translate(var(--button-width)) rotate(180deg) scaleX(1);
  }
  83.33% {
    transform: translate(0) rotate(180deg) scaleX(var(--width-multiplier));
  }
  100% {
    transform: translate(0) rotate(1turn) scaleX(1);
  }
}

@keyframes mask1 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes mask2 {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes move {
  50% {
    opacity: 1;
  }
  to {
    left: 296px;
  }
}
