.fade-in {
  opacity: 0.2;
  transition: opacity 0.6s cubic-bezier(0, 0, 0.58, 1);
}
.fade-in.in-view {
  opacity: 1;
}

.fade-in-up {
  opacity: 0.2;
  transform: translateY(20px);
  transition:
    opacity 0.7s cubic-bezier(0, 0, 0.58, 1),
    transform 0.7s cubic-bezier(0, 0, 0.58, 1);
}
.fade-in-up.in-view {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-scale {
  opacity: 0.2;
  transform: scale(0.6);
  transition:
    opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.fade-in-scale.in-view {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

.block-reveal {
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: var(--color-primary);
  }
  span {
    opacity: 0;
  }
  &.in-view {
    span {
      animation: appear-text 0.0001s 0.5s linear forwards;
    }
    &:after {
      animation: rev-block 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
  }
}

@keyframes rev-block {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

@keyframes appear-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loaderAbsolute {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  color: var(--color-white);
  font-size: 17px;
  background: rgba(0, 0, 0, 0.8);

  // &_inner {
  //   background: #000;
  // }

  img {
    max-width: 120px;
  }

  .text {
    padding: 6px 0 6px 10px;
  }
}
