@use 'sass:map';
@use 'sass:math';
@use 'sass:meta';

$breakpoints: (
  'small': (
    max-width: 767.98px,
  ),
  'medium': (
    min-width: 768px,
  ),
  'untilLarge': (
    max-width: 1279.98px,
  ),
  'large': (
    min-width: 1280px,
  ),
  'huge': (
    min-width: 1600px,
  ),
  'max': (
    min-width: 1921px,
  ),
) !default;

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map.has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{meta.inspect(map.get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map.keys($breakpoints)}.";
  }
}

@function strip-units($number) {
  @return math.div($number, ($number * 0 + 1));
}

// Calculate from px to rem
@function rem($pxval) {
  @if not math.is-unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  @return calc($pxval / 16) * 1rem;
}
