@use './styles/_mixins.scss' as *;
.CookieConsent {
  &_container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    z-index: 50;
    max-width: 27rem;
    margin-left: auto;

    @include breakpoint(medium) {
      right: 20px;
      bottom: 20px;
      max-width: 25rem;
    }
  }

  &_popup {
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 12px 10px;

    @include breakpoint(medium) {
      border: 1px solid #e5e7eb;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h2 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &_description {
    margin-bottom: 10px;
    font-size: 13px;
  }

  &_buttonContainer {
    @include breakpoint(medium) {
      display: flex;
      gap: 1rem;
    }
  }

  &_button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 0.375rem;
    color: var(--color-primary);
    font-size: 13px;
    font-weight: 500;
    border: 2px solid var(--color-primary);
    cursor: pointer;
    transition:
      background 0.2s ease-in-out,
      color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;

    @include breakpoint(small) {
      width: 100%;
      &:first-child {
        margin-bottom: 4px;
      }
    }

    &.acceptButton {
      background-color: var(--color-primary);
      color: white;
    }

    &:hover {
      background: var(--color-dark);
      color: var(--color-white);
      border-color: var(--color-dark);
    }
  }
}
